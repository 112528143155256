.ac-tr
  background-color: white

.ac__td-heading
  border: 1px solid #03178C
  padding: 0.5em 1em
  background-color: #03178C
  color: white
  align-items: center

.ac__edit-wrapper
  width: 100%
  display: flex
  button
    background-color: white
    font-size: 1.2em
    color: #03178C
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer
    border: 1px solid #03178C
    box-sizing: border-box


.ac__td-input
  font-size: 1em
  border: 1px solid #03178C
  outline: none
  padding: 0.5em 1em
  box-sizing: border-box
  width: 100%
  cursor: pointer


.ac__row-info
  padding: 0.5em 1em
  display: flex
  align-items: center
  justify-content: space-between
  background-color: white
  box-sizing: border-box

.ac__row-info__btn-wrapper
  display: flex
  justify-content: center
  align-items: center

.ac__row-info__btn
  display: flex
  justify-content: center
  align-items: center
  color: #03178C
  border: none
  background-color: white
  cursor: pointer
  font-size: 1.2em
  padding: 0 0.7em


.ac__td-btnWrapper
  display: flex
  flex-direction: row