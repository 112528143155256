
.loginForm-content
  display: flex
  flex-direction: column
  gap: 1em
  margin: 1em 3em
  text-align: center
  p
    font-size: 1.2em

.loginForm-btnPurple
  background-color: #03178C
  color: white
  cursor: pointer
  border: none
  padding: 0.7em 1.1em
  font-weight: bold
  width: 100%
  font-size: 1em

.loginForm-heading
  color: #03178C
  font-size: 1.5em

.loginForm-link
  text-decoration: underline

.loginForm__input
  width: 100%
  box-sizing: border-box
  border: 2px solid #03178C
  padding: 0.7em 0.9em
  &:focus
    outline: none
  &::placeholder
    font-size: 1.1em

.togglePassword-btn
  position: absolute
  top: 50%
  right: 10px
  transform: translateY(-50%)
  color: #03178C
  background-color: inherit
  border: none
  cursor: pointer
  display: flex
  align-items: center
  justify-content: center
  padding: 0.3em
  &:hover
    background-color: #d9d9d9
    border-radius: 100%

