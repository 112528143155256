.feedback-wrapper
  display: flex
  flex-direction: column
  align-items: center
  margin: 3em

.feedback-content
  width: 60%
  text-align: center

.feedback-user-wrapper
  box-sizing: border-box
  background-color: white
  width: 100%
  margin: 1em 0
  padding: 1em
  text-align: start