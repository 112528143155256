*{
  margin: 0;
  padding: 0;
}
html{
  min-height: 100vh;
  background-color: #F2F2F2;
}

body{
  font-family: "Roboto",sans-serif;
}

*{
  font-family: 'Roboto', sans-serif;
}

li{
  list-style: none;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  ;
}

::-webkit-scrollbar-thumb {
  background-color: #03178C;

}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #03178C #F0F4FF;
}

/* Edge и Internet Explorer */
*::-ms-scrollbar {
  width: 10px;
}

*::-ms-scrollbar-thumb {
  background-color: #03178C;
}

*::-ms-scrollbar-track {
  background-color: #D9D9D9;
}
