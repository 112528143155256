.headerMain-wrapper
  background-color: #03178C
  padding: 1.5em 3em
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  gap: 1em
  width: 30em

.headerMain-content
  gap: 3em
  display: flex
  flex-direction: column
  width: 100%

.headerMain__button-wrapper
  display: flex
  flex-direction: column
  gap: 1em

.headerMain__btnWhite
  background-color: white
  color: #03178C
  cursor: pointer
  border: none
  padding: 0.7em 1.1em
  font-weight: bold
  width: 100%
  font-size: 1em

.headerMain-logo
  width: 100%

@media (max-width: 870px)
  .headerMain-content
    flex-direction: row
    justify-content: space-between
    width: 100%
    gap: 1em
    align-items: center
  .headerMain__button-wrapper
    flex-direction: row
    align-items: center
  .headerMain-logo
    width: auto
  .headerMain-wrapper
    width: 100%

@media (max-width: 600px)
  .headerMain__button-wrapper
    flex-direction: column
    gap: 1em

@media (max-width: 400px)
  .headerMain-wrapper
    gap: 1em
    padding: 1.5em 2em

@media (max-width: 380px)
  .headerMain-content
    flex-direction: column
    gap: 1em
    justify-content: center
    align-items: center
  .headerMain-logo
    width: 50%

