.footer-wrapper
  display: flex
  flex-direction: row
  gap: 10em
  background-color: #03178C
  color: white
  padding: 2em 4em
  justify-content: center


@media (max-width: 1020px)
  .footer__logo-container
    align-items: flex-start

@media (max-width: 950px)
  .footer-wrapper
    gap: 5em

@media (max-width: 805px)
  .footer-wrapper
    gap: 2em

@media (max-width: 715px)
  .footer-wrapper
    flex-direction: column
    padding: 2em 8em
    align-items: center
    div
      width: 100% !important

@media (max-width: 500px)
  .footer-wrapper

    padding: 2em 3em
