.main-wrapper
  display: flex
  flex-direction: row
  min-height: 100vh

.main-content-wrapper
  padding: 4em
  display: flex
  flex-direction: column
  gap: 1.1em
  align-items: center


.main-heading
  color: #03178C
  font-size: 2em


.main__list-wrapper
  display: flex
  flex-direction: column
  gap: 1.2em
  width: 60%
  text-align: justify

  h2
    color: #035AA6

  li
    font-size: 1.1em

.main-btnPink
  background-color: #035AA6
  color: white
  cursor: pointer
  border: none
  padding: 0.7em 1.1em
  font-weight: bold
  font-size: 1em

.main__btn-wrapper
  display: flex
  justify-content: flex-end
  flex-direction: row
  width: 100%

@media (max-width: 1100px)
  .main__list-wrapper
    width: 90%

@media (max-width: 870px)
  .main-wrapper
    flex-direction: column
  .main-content-wrapper
    width: 100%
    padding: 2em


@media (max-width: 380px)
  .main__btn-wrapper
    justify-content: center
  .main__list-wrapper
    h2
      font-size: 100%
  .main-content-wrapper
    padding: 2em
  .main-heading
    font-size: 1.5em