.history-content__wrapper
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  gap: 1.2em
  margin: 2em 0


.history-table__wrapper
  display: flex
  justify-content: center
  align-items: center
  font-size: 1em


.history-table
  text-align: end
  border-spacing: 6px 12px

.history-table__cell, .history-table__head, .history-table__cell--btn
  padding: 0.2em 1em

.history-table__cell, .history-table__cell--btn
  background-color: white
  font-size: 1em
  box-sizing: border-box
  line-height: 1.5
.history-table__head
  color: #035AA6
  font-size: 1.1em

.history-table__cell--btn
  &:hover
    box-shadow: 2px 5px 8px #035AA6



.history__header
  color: #03178C
  text-align: center
  font-weight: bold
  margin-bottom: 1em


.history-table__btn
  background-color: white
  font-weight: bold
  border: none
  font-size: 1em
  color: #03178C
  cursor: pointer
  width: 100%


@media (max-width: 510px)
  .history-table
    font-size: 0.9em

@media (max-width: 450px)
  .history-table
    font-size: 0.8em

@media (max-width: 401px)
  .history-table
    font-size: 0.7em

@media (max-width: 360px)
  .history-table__wrapper
    overflow-x: auto
    max-width: 100%
  .history-table
    font-size: 0.65em