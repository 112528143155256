.modal
  display: none
  position: fixed
  z-index: 1
  left: 0
  top: 0
  width: 100%
  height: 100%
  overflow: auto
  background-color: rgba(0,0,0,0.4)

  &.display-block
    display: flex
    align-items: center
    justify-content: center

  .modal-main
    position: relative
    background-color: white
    padding: 1.3em

    width: 30%
    min-width: 250px
    max-width: 800px

    .modal-btnClose
      position: absolute
      top: 15px
      right: 15px
      color: #aaa
      border: none
      background-color: inherit

      &:hover, &:focus
        color: black
        text-decoration: none
        cursor: pointer
