
.applicationForm-content
  display: flex
  flex-direction: column
  gap: 1em
  margin: 1em 3em
  text-align: center
  p
    font-size: 1.2em

.applicationForm-btnPurple
  background-color: #03178C
  color: white
  cursor: pointer
  border: none
  padding: 0.7em 1.1em
  font-weight: bold
  width: 100%
  font-size: 1em




.applicationForm-heading
  color: #03178C
  font-size: 1.5em

.applicationForm-link
  text-decoration: underline

.applicationForm__input
  border: 2px solid #03178C
  padding: 0.5em 0.8em
  &:focus
    outline: none
  &::placeholder
    font-size: 1.1em

.applicationForm-btnPurple:disabled
    background-color: #cccccc
    cursor: not-allowed

