.input-style
  border: 2px solid #03178C
  application
  padding: 0.5em 0.8em
  width: 100%
  box-sizing: border-box

  &:focus
    outline: none
  &::placeholder
    font-size: 1.1em
