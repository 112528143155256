.container-app{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.content-app{
    flex: 1 0 auto;
}

.footer-app{
    flex-shrink: 0;
}
