.admin-input
  border: 2px solid #03178C
  padding: 0.6em 0.8em
  width: 100%
  box-sizing: border-box
  &:focus
    outline: none
  &::placeholder
    font-size: 1.1em

.admin__btn:disabled
  background-color: #cccccc !important
  cursor: not-allowed  !important
  color: white !important


.togglePasswordAdmin-btn
  position: absolute
  top: 50%
  right: 10px
  transform: translateY(-50%)
  color: #03178C
  background-color: inherit
  border: none
  cursor: pointer
  display: flex
  align-items: center
  justify-content: center
  padding: 0.3em
  &:hover
    background-color: #d9d9d9
    border-radius: 100%
