.check-wrapper
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  font-size: 1em


.check-content-wrapper
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  margin: 2em
  gap: 1em
  width: 40%

.check__result-wrapper
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  gap: 1em
  width: 100%


.check__result-heading
  text-align: center
  font-size: 1.45em
  font-weight: bold

.check__select
  border: 2px solid #03178C
  application
  padding: 0.5em 0.8em
  outline: none
  width: 100%

.check__result
  display: flex
  flex-direction: column
  gap: 1em
  text-align: justify
  width: 100%

.check__input-wrapper
  display: flex
  flex-direction: row
  gap: 3em
  align-items: center
  width: 100%

.check__btnPurple
  background-color: #03178C
  color: white
  cursor: pointer
  border: none
  padding: 0.45em 1.1em
  application
  font-weight: bold
  font-size: 1em

.error
  font-weight: bold
  color: #F72F0C

.check__feedback-wrapper
  width: 100%
  display: flex
  flex-direction: column
  gap: 1em

.check__question-wrapper
  display: flex
  flex-direction: column
  width: 100%
  align-items: center
  gap: 1em
  .check__question-btn
    display: flex
    flex-direction: row
    width: 100%
    justify-content: space-between

.check__thank-you-wrapper
  width: 100%
  margin-top: 1em
.check__btnPink
  background-color: #035AA6
  color: white
  cursor: pointer
  border: none
  padding: 0.7em 1.1em
  application
  font-weight: bold
  font-size: 1em

.check__btnPink--disabled
  background-color: #b6b5b5
  color: white

.result__li
  list-style: disc
  margin-left: 2em
  color: #03178C
  span
    color: black

@media (max-width: 620px)
  .check__input-wrapper
    flex-direction: column
    gap: 1em
  .check__btnPink
    width: 100%
  .check__btnPink--disabled
    width: 100%
  .check-content-wrapper
    width: 70%


