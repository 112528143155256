.create-user-wrapper
  display: flex
  flex-direction: column
  align-items: center
  margin: 3em

.create-user-content
  width: 60%
  text-align: center

.create-user__btn
  background-color: #03178C
  color: white
  cursor: pointer
  border: none
  padding: 0.7em 1.1em
  font-weight: bold
  width: 10em
  font-size: 1em
  &:disabled
    background-color: gray
    cursor: not-allowed

.user-wrapper
  box-sizing: border-box
  background-color: white
  width: 100%
  margin: 1em 0
  padding: 1em
  text-align: start


.registerForm-content
  margin-top: 1em
  display: flex
  flex-direction: column
  gap: 1em
  text-align: center
  p
    font-size: 1.2em

.registerForm-btnPurple
  background-color: #03178C
  color: white
  cursor: pointer
  border: none
  padding: 0.7em 1.1em
  font-weight: bold
  width: 100%
  font-size: 1em
  &:disabled
    background-color: gray
    cursor: not-allowed



.registerForm-heading
  color: #03178C
  font-size: 1.5em

.registerForm-link
  text-decoration: underline

.registerForm__input, .registerForm__select
  border: 2px solid #03178C
  padding: 0.5em 0.8em
  &:focus
    outline: none
  &::placeholder
    font-size: 1.1em

@media (max-width: 950px)
  .registerForm-content
    margin: 1em 1.5em
@media (max-width: 1130px)
  .registerForm-heading
    font-size: 1.2em
