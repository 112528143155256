.header-admin-wrapper
  background-color: #03178C
  padding: 0.7em 4em
  display: flex
  align-items: center
  justify-content: space-between
  box-sizing: border-box

.header-admin__button-wrapper
  display: flex
  gap: 4em

.header-admin__btnWhite
  background-color: white
  color: #03178C
  cursor: pointer
  border: none
  padding: 0.7em 1.1em
  font-weight: bold
  width: 100%
  font-size: 1em


@media (max-width: 730px)
  .header-admin-wrapper
    gap: 1em

@media (max-width: 650px)
  .header-admin__button-wrapper
    flex-direction: column
    gap: 0.5em
  .header-admin__btnWhite
    padding: 0.3em 1.1em


@media (max-width: 400px)
  .header-admin-wrapper
    gap: 1em
    padding: 1.5em 2em
