.header-wrapper
  background-color: #03178C
  padding: 0.7em 4em
  display: flex
  align-items: center
  justify-content: space-between
  box-sizing: border-box

.header__button-wrapper
  display: flex
  gap: 4em

.header__btnWhite
  background-color: white
  color: #03178C
  cursor: pointer
  border: none
  padding: 0.7em 1.1em
  font-weight: bold
  width: 100%
  font-size: 1em

.column
  display: flex
  flex-direction: row
  gap: 4em

@media (max-width: 900px)
  .header__button-wrapper
    flex-wrap: wrap
    flex-direction: row
    justify-content: space-between
  .column
    flex: 1
    display: flex
    gap: 1em
    flex-direction: column

@media (max-width: 560px)
  .header-wrapper
    flex-direction: column
    gap: 1em
  .header__button-wrapper
    gap: 2em


@media (max-width: 390px)
  .header__button-wrapper
    flex-direction: column
    gap: 1em



