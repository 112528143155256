.ac-wrapper
  margin: 2em
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

.ac__table-wrapper
  min-width: 40%
  margin-top: 1.5em


@media (max-width: 460px)
  .ac-wrapper
    margin: 1em
    li
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center
      gap: 0.5em
    .ac__li-heading
      width: 100%